import { useMemo } from "react"

import {
  deviceReadingType,
  p1DeviceReadingType,
  p2DeviceReadingType,
} from "src/data/devices/logic/deviceConstants"
import { getDeviceHardwareType } from "src/data/devices/logic/deviceLogic"
import {
  DeviceReadingType,
  HardwareType,
} from "src/data/devices/types/deviceTypes"
import { TParadiseDevice } from "src/data/paradise/paradiseDevices/types/paradiseDeviceQueryTypes"
import { Combobox } from "src/ui/Combobox/Combobox"
import { TComboboxOption } from "src/ui/Combobox/ComboboxStateful"

export function ParadiseSelectDeviceReading({
  device,
  selectedDeviceReading,
  onChange,
  searchedValue,
  input,
  onInput,
}: {
  device: TParadiseDevice | undefined
  selectedDeviceReading: string
  onChange: (value: string) => void
  searchedValue: string
  input: string
  onInput: (value: string) => void
}) {
  const availableReadingOptions = useMemo(() => {
    if (device) {
      if (
        !device.hardware_version ||
        getDeviceHardwareType(device.hardware_version).type === HardwareType.P1
      ) {
        return [
          ...convertToComboboxOptions(p1DeviceReadingType),
          ...convertToComboboxOptions(deviceReadingType),
        ]
      } else {
        return [
          ...convertToComboboxOptions(p2DeviceReadingType),
          ...convertToComboboxOptions(deviceReadingType),
        ]
      }
    } else {
      return []
    }
  }, [device])

  return (
    <Combobox
      label="Reading type"
      selectedValue={selectedDeviceReading}
      options={availableReadingOptions.filter((reading) =>
        reading.label.toLowerCase().includes(searchedValue.toLowerCase())
      )}
      popoverMaxHeight="500px"
      onChange={(value) => {
        onChange(value)
      }}
      input={input}
      onInput={onInput}
    />
  )
}

function convertToComboboxOptions(
  arr: readonly DeviceReadingType[]
): TComboboxOption[] {
  return arr.map((s) => ({ value: s, label: s }))
}
