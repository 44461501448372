import { useState } from "react"

import { DeviceReadingType } from "src/data/devices/types/deviceTypes"
import { localStorageFactory } from "src/utils/storageUtil"

const DEFAULT_QUICK_LINKS: DeviceReadingType[] = [
  "temperature",
  "humidity",
  "sound_level",
  "battery",
  "battery_charging_current",
  "os_uptime_secs",
  "motion_events",
  "magnetic_flux",
  "tvoc_ppb",
  "accelerometer_z",
]

const QUICK_LINKS_STORAGE_KEY = "minut.paradise.quickLinks"

const localStorageQuickLinks = localStorageFactory<DeviceReadingType[]>({
  key: QUICK_LINKS_STORAGE_KEY,
})

export function useParadiseDeviceReadingsQuickLinks() {
  const [quickLinks, setQuickLinks] =
    useState<DeviceReadingType[]>(initilizeQuickLinks)

  function addQuickLink(quickLink: DeviceReadingType) {
    const newQuickLinks = [...quickLinks, quickLink]

    setQuickLinks(newQuickLinks)
    localStorageQuickLinks.set(newQuickLinks)
  }

  function removeQuickLink(quickLink: DeviceReadingType) {
    const newQuickLinks = quickLinks.filter((link) => link !== quickLink)

    setQuickLinks(newQuickLinks)
    localStorageQuickLinks.set(newQuickLinks)
  }

  function toggleQuickLink(quickLink: DeviceReadingType) {
    if (quickLinks.includes(quickLink)) {
      removeQuickLink(quickLink)
    } else {
      addQuickLink(quickLink)
    }
  }

  function hasQuickLink(quickLink: DeviceReadingType) {
    return quickLinks.includes(quickLink)
  }

  return {
    quickLinks,
    addQuickLink,
    removeQuickLink,
    toggleQuickLink,
    hasQuickLink,
  }
}

function initilizeQuickLinks() {
  const storedQuickLinks = localStorageQuickLinks.get()
  if (storedQuickLinks) {
    return storedQuickLinks
  }

  return DEFAULT_QUICK_LINKS
}
