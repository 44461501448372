import { Redirect, Switch } from "react-router-dom"
import styled from "styled-components"

import { EmptyState } from "src/components/EmptyState"
import { ParadiseGraphViews } from "src/components/Paradise/ParadiseDevices/ParadiseDeviceReadings/ParadiseGraphViews/ParadiseGraphViews"
import {
  PARADISE_QUICK_INSIGHTS_TYPE_PARAM_KEY,
  ParadiseQuickInsights,
} from "src/components/Paradise/ParadiseDevices/ParadiseDeviceReadings/ParadiseQuickInsights/ParadiseQuickInsights"
import { ParadiseRoute } from "src/components/ParadiseRoute/ParadiseRoute"
import { isValidDeviceReadingtype } from "src/data/devices/logic/deviceLogic"
import { useFlags } from "src/hooks/useFlags"
import { Routes } from "src/router/routes"
import { Breadcrumbs } from "src/ui/Breadcrumbs/Breadcrumbs"
import { mColors } from "src/ui/colors"
import Svg404 from "src/ui/icons/404.svg"
import { MText } from "src/ui/MText"
import { spacing } from "src/ui/spacing"
import { ITab } from "src/ui/Tabs/Tab"
import { Tabs } from "src/ui/Tabs/Tabs"

export function ParadiseDeviceReadings({
  deviceId,
  type,
}: {
  deviceId: string
  type: string
}) {
  const { paradiseGraphs2_0 } = useFlags()

  const tabs: ITab[] = [
    {
      id: Routes.ParadiseDeviceQuickInsights.location({ deviceId }).pathname,
      label: "Quick insights",
      view: <ParadiseQuickInsights deviceId={deviceId} type={type} />,
    },
    {
      id: Routes.ParadiseDeviceGraphViews.location({ deviceId }).pathname,
      label: "Graph views",
      view: <ParadiseGraphViews />,
    },
  ]

  function renderContent() {
    if (!paradiseGraphs2_0) {
      if (!type || !isValidDeviceReadingtype(type)) {
        return (
          <EmptyState title="Could not find reading type" icon={<Svg404 />} />
        )
      }

      return <ParadiseQuickInsights deviceId={deviceId} type={type} />
    }

    // Make sure we redirect all old routes to the new ones
    if (type && isValidDeviceReadingtype(type)) {
      return (
        <Redirect
          to={{
            pathname: Routes.ParadiseDeviceQuickInsights.location({ deviceId })
              .pathname,
            search: `?${PARADISE_QUICK_INSIGHTS_TYPE_PARAM_KEY}=${type}`,
          }}
        />
      )
    }

    return (
      <Switch>
        <ParadiseRoute
          path={
            Routes.ParadiseDeviceQuickInsights.location({
              deviceId,
            }).pathname
          }
          render={({ match }) => {
            const type = match.params.type

            return <ParadiseQuickInsights deviceId={deviceId} type={type} />
          }}
        />

        <ParadiseRoute
          path={
            Routes.ParadiseDeviceGraphViews.location({
              deviceId,
            }).pathname
          }
        >
          <ParadiseGraphViews />
        </ParadiseRoute>
      </Switch>
    )
  }

  return (
    <div>
      <TopSection>
        <Breadcrumbs
          items={[
            {
              label: "Devices",
              to: Routes.ParadiseDevices.location(),
            },
            {
              label: "Device readings",
              to: Routes.ParadiseDeviceReadings.location({
                deviceId,
                type: "temperature",
              }),
            },
          ]}
        />

        <MText variant="heading2" marginBottom={spacing.M}>
          Device readings
        </MText>

        {paradiseGraphs2_0 && (
          <Tabs
            labels={tabs}
            hideLabels={tabs.length === 1}
            usePaths
            hideTabsBorder
          >
            <div></div>
          </Tabs>
        )}
      </TopSection>

      {renderContent()}
    </div>
  )
}

const TopSection = styled.div`
  padding-top: ${spacing.M};
  padding-inline: ${spacing.L};

  background-color: ${mColors.neutral};
  border-bottom: 1px solid ${mColors.divider};
`
