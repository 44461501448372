import { ParadiseLayout } from "src/components/Paradise/sharedStyles"
import { MBanner } from "src/ui/MBanner/MBanner"

export function ParadiseGraphViews() {
  return (
    <ParadiseLayout>
      <MBanner title="Not implemented" type="warning" fullWidth />
    </ParadiseLayout>
  )
}
